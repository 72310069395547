body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*{font-size:14px}.device-message{margin-top:99px;white-space:pre-wrap}.no-highlight{-webkit-user-select:none;user-select:none}.popup{z-index:10;background-color:#fff;display:flex;justify-content:center;padding:15px;position:absolute;width:40vw;height:30vh;border:1px solid #000;border-radius:5px;box-shadow:5px 5px 5px gray;right:1vw;bottom:2vh;flex-wrap:wrap}.status-message{margin-top:30px;white-space:pre-wrap;height:calc(75vh - 120px);overflow-y:scroll}.flex{display:flex;justify-content:center;align-items:center;width:100%}.pair-device-button{margin-top:-5vh;position:absolute;cursor:pointer;text-align:center;padding:10px 10px;font-size:44px;font-weight:bold;color:#0b8aad;border:2px solid #0b8aad;border-radius:10px}.pair-device-button:hover{background-color:#7dbcd2;color:#fff;border:none}.settings-popup{width:400px;padding:29px;border:1px solid #7dbcd2;border-radius:58px;display:flex;flex-wrap:wrap;justify-content:center;background-color:#fff;box-shadow:5px 5px rgba(128,128,128,.1607843137);position:absolute;right:6vw;top:15px}.settings-popup .button{width:70%}.button{cursor:pointer;padding:10px 20px;background-color:#0b8aad;color:#fff;border-radius:15px;margin-top:15px;font-size:22px}.button:hover{background-color:#7dbcd2}.option{font-size:18px;margin-bottom:10px;font-weight:bold;width:59%}.load-csv{width:55%;font-size:25px;margin-left:30px}
